<template>
  <div class="flex">
    <b-form-input
      id="filterInput"
      type="search"
      placeholder="Buscar..."
      @input="$emit('search-text', $event)"
    />
    <b-button
      v-if="myRole === 'master'"
      class="ml-1 btn-icon rounded-circle"
      variant="success"
      @click="$emit('add', true)"
    >
      <feather-icon icon="FilePlusIcon" />
    </b-button>
    <b-button
      v-if="myRole === 'master'"
      class="ml-1 btn-icon rounded-circle"
      variant="success"
      @click="$bvModal.show('users-tools-modal')"
    >
      <feather-icon icon="UserPlusIcon" />
    </b-button>
    <b-button
      class="ml-1"
      variant="primary"
      @click="$bvModal.show('tools-control-modal'); viewMyTools = true; backTool = false"
    >
      Usuario
    </b-button>
    <b-button
      class="ml-1"
      variant="primary"
      @click="$bvModal.show('tools-control-modal'); backTool = false; viewMyTools = false"
    >
      Retiro
    </b-button>
    <control-modal
      type="repuestos"
      :tools="allTools"
      :users="allUsers"
      :is-return="backTool"
      :only-view="viewMyTools"
    />
    <users-modal :users="allUsers" />
  </div>
</template>

<script>
import { BButton, BFormInput } from 'bootstrap-vue'
import ControlModal from './Controlv2.vue'
import UsersModal from './Usersv2.vue'

export default {
  components: {
    BButton, BFormInput, UsersModal, ControlModal,
  },
  props: {
    myRole: String,
    allUsers: {
      type: Array,
      default: () => [],
    },
    allTools: Array,
  },
  data() {
    return {
      backTool: false,
      viewMyTools: false,
    }
  },
}
</script>
