class Tool {
  constructor() {
    this.description = ''
    this.old_code = ''
    this.in_use = 0
    this.brand = ''
    this.size = ''
    this.modelo = ''
    this.category = ''
    this.location = ''
    this.code = ''
    this.stock = 1
    this.single = true
    this.images = []
  }
}

function rawTools(tools) {
  const parsedTools = []
  Object.keys(tools).forEach(element => {
    const obj = tools[element]
    obj.key = element
    parsedTools.push(obj)
  })
  return parsedTools
}

export default { Tool, rawTools }
