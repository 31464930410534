function rawUsers(users) {
  const parsedUsers = []
  Object.keys(users).forEach(element => {
    const item = { value: element, name: `${users[element].value} - ${users[element].nombre}`, k: users[element].value }
    parsedUsers.push(item)
  })
  return parsedUsers
}

function generateInUseList(usersList, allUsers) {
  if (usersList) {
    if (usersList.length === 1) {
      const result = allUsers.filter(obj => obj.value === usersList[0])
      return result[0] ? result[0].k : 0
    }
    const temp = []
    usersList.forEach(element => {
      const rest = allUsers.filter(obj => obj.value === element)
      if (rest[0]) {
        temp.push((rest[0].k).toString())
      }
    })
    const formatter = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' })
    return formatter.format(temp)
  }
  return ''
}

export default { rawUsers, generateInUseList }
