<template>
  <div id="repuestos">
    <b-card>
      <Options
        :my-role="myRole"
        :all-tools="allTools"
        :all-users="allUsers"
        @add="addTool"
        @search-text="search = $event"
      />
    </b-card>
    <b-card class="bg-primary">
      <b-row class="font-bold text-white">
        <b-col md="1">Código</b-col>
        <b-col md="4">Nombre</b-col>
        <b-col md="2">Marca</b-col>
        <!-- <b-col md="1">Ubicación</b-col> -->
        <b-col md="2">QTY Disponible</b-col>
        <b-col md="1">Retirados</b-col>
        <b-col md="2">Usuarios</b-col>
      </b-row>
    </b-card>
    <template v-for="(tool, i) in allTools">
      <b-card
        v-if="exist(tool.desc)"
        :key="i"
        class="mb-1"
      >
        <b-row>
          <b-col md="1">{{ tool.code }}</b-col>
          <b-col md="4">{{ tool.desc }}</b-col>
          <b-col md="2">{{ tool.marca }}</b-col>
          <!-- <b-col md="1">{{ tool.ubi }}</b-col> -->
          <b-col md="2" class="text-center">{{ tool.stock - (tool.in_use.length || 0) }}</b-col>
          <b-col md="1" class="text-center">{{ (tool.in_use.length || 0) }}</b-col>
          <b-col md="2">{{ getNameUser(tool.in_use) }}</b-col>
        </b-row>
      </b-card>
    </template>
    <modal
      :tool="objToEdit"
      type="repuestos"
    />
  </div>
</template>

<script>
import {
  BCard, BCol, BRow,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import modal from './Components/Modal.vue'
import 'firebase/database'
import Options from './Components/Options.vue'
import ToolsUtil from './Components/Tool'
import usersUtil from './Components/Users'

export default {
  name: 'BodegaRepuestos',
  components: {
    BCard, BCol, BRow, modal, Options,
  },
  data() {
    return {
      newtool: {
        desc: '',
        oldcode: '',
        in_use: 0,
        marca: '',
        size: '',
        modelo: '',
        cat: '',
        ubi: '',
        code: '',
        stock: 1,
        single: true,
        img: [],
      },
      allTools: [],
      objToEdit: {},
      search: null,
      allUsers: [],
      // Role options
      myRole: null,
    }
  },
  mounted() {
    this.myRole = this.$route.meta.currentUserRol
    // Get tools in firebase
    firebase.database().ref('bodegas/repuestos').on('value', snap => {
      this.allTools = ToolsUtil.rawTools(snap.val())
    })
    // Get users in firebase
    firebase.database().ref('bodegas/users').on('value', snap => {
      this.allUsers = usersUtil.rawUsers(snap.val())
    })
    // const filtered = this.filter(this.allTools, score => score.desc == '%Term%')
    // console.log('filtrado', filtered)
  },
  methods: {
    filter(obj, predicate) {
      return Object.assign(...Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .map(key => ({ [key]: obj[key] })))
    },
    getNameUser(usersList) {
      return usersUtil.generateInUseList(usersList, this.allUsers)
    },
    editTool(obj) {
      if (this.myRole !== 'master') return
      this.objToEdit = JSON.parse(JSON.stringify(obj))
      this.$bvModal.show('tools-modal')
    },
    addTool() {
      this.objToEdit = JSON.parse(JSON.stringify(this.newtool))
      this.$bvModal.show('tools-modal')
    },
    exist(name) {
      const nombre = name.toUpperCase()
      const busqueda = this.search ? this.search.toUpperCase() : ''
      if (busqueda) {
        if (nombre.indexOf(busqueda) !== -1) {
          return true
        }
        return false
      }
      return true
    },
  },
}
</script>
